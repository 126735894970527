/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  usePostProjectDefaultImputationsMutation,
  usePostProjectMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Field } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import ProjectsModalItem from './ProjectsModalItem'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  ProjectStoreValidator,
  Client,
  Software,
  ProjectStatus,
  Project,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface ProjectStore extends ProjectStoreValidator {
  createDefaultImputation: boolean;
}

const projectBulkSchema = Yup.object().shape<Shape<ProjectStore>>({
  clientId: Yup.string(),
  id: Yup.string().required('Le code projet est obligatoire'),
  name: Yup.string().required('Le libellé projet est obligatoire').max(60, 'Le libellé ne peut pas excéder 60 caractères'),
  softwareId: Yup.string().required('Le logiciel est obligatoire'),
  statusId: Yup.string().required('Le statut est obligatoire'),
  description: Yup.string(),
  createDefaultImputation: Yup.boolean(),
}).required()

type ProjectRequest = FormikContextType<ProjectStore>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

const CheckboxContainer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 4px;
  width: 100%;
  height: 56px;
  margin: 0;
  label {
    height: 100%;
  }
`

const CenteredCheckbox = styled(CheckboxWithLabel)`
  margin-top: -2px;
  margin-left: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface ProjectsNewModalProps {
  open: boolean;
  handleClose: () => void;
  clientList: Client[];
  isFetchingClientList: boolean;
  softwareList: Software[];
  isFetchingSoftwareList: boolean;
  statusList: ProjectStatus[];
  isFetchingStatusList: boolean;
  projectList: Project[];
}

const ProjectsNewModal: React.FC<ProjectsNewModalProps> = ({
  open,
  handleClose,
  projectList = [],
  ...props
}) => {

  const [
    submitNewProject,
  ] = usePostProjectMutation()
  const [
    submitNewDefaultImputation,
  ] = usePostProjectDefaultImputationsMutation()

  const onSubmit = (values: ProjectStore, { setSubmitting, resetForm }: FormikHelpers<ProjectStore>) => {
    submitNewProject(values)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .then((response) => {
        if (('data' in response) && values.createDefaultImputation) {
          return submitNewDefaultImputation({ projectId: response.data.id })
        }
        return response
      }).then((response) => {
        if (!isApiError(response)) {
          resetForm()
          handleClose()
        } else {
          toast.error('Une erreur est survenue lors de la création du projet.')
          setSubmitting(false)
        }
      }).catch(console.error)
  }

  const formikForm: ProjectRequest = useForm<ProjectStore>(
    {
      initialValues: {
        id: '',
        name: '',
        softwareId: 1,
        statusId: 0,
        clientId: '',
        description: '',
        createDefaultImputation: true,
      },
      onSubmit: onSubmit,
      validationSchema: projectBulkSchema,
    },
  )

  const projectCodeHelper = useMemo(() => {
    const { id } = formikForm.values
    if (id.length > 2) {
      const codes = projectList.filter((p) => p.id.toLowerCase().startsWith(id.toLowerCase())).map((p) => p.id).join(', ')
      return codes ? `Codes existants : ${codes}` : ''
    }
  }, [ formikForm.values.id ])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          Nouveau projet
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <ProjectsModalItem
            {...props}
            projectCodeHelper={projectCodeHelper}
          />
          <CheckboxContainer>
            <Field
              name="createDefaultImputation"
              component={CenteredCheckbox}
              Label={{ label: 'Créer avec les imputations projet' }}
              type="checkbox"
            />
          </CheckboxContainer>
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            type="submit"
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default ProjectsNewModal

/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetActiveProjectListQuery,
  useGetClientListQuery,
  useGetActivityListQuery,
  useDeleteActivityMutation,
  useGetSettingsQuery,
  useGetUserListQuery,
} from 'store/api'
import DateUtils from 'helpers/DateUtils'
import { exportToXLSX } from 'helpers/tableUtils'
import {
  useAppDispatch,
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import {
  getActivitiesForm,
  setActivitiesForm,
} from 'store/slices/activitiesFormSlice'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Collapse,
  Pagination,
} from '@mui/material'
import {
  DeleteOutlined,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import { toast } from 'react-toastify'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import Table from 'components/Table/Table'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import ActivitiesCalendar from './ActivitiesComponents/ActivitiesCalendar'
import ActivitiesFilters from './ActivitiesComponents/ActivitiesFilters'
import ActivitiesTableMobileCard from './ActivitiesComponents/ActivitiesTableMobileCard'
import ActivitiesModal from './ActivitiesComponents/ActivitiesModal'
import ActivitiesDeleteModal from './ActivitiesComponents/ActivitiesDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'
import type {
  Activity,
  ActivityListParams,
  Project,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const activitiesSchema = Yup.object({
  startDate: Yup.string(),
  endDate: Yup.string().test(
    'startDate',
    'La date de fin ne peut pas être inférieure à celle de début',
    (item = '', { parent }: {parent: ActivityListParams})=> new Date(item) > new Date(parent.startDate || ''),
  ),
  userIds: Yup.array(Yup.number().required()).nullable(),
  clientIds: Yup.array(Yup.string().required()).nullable(),
  projectIds: Yup.array(Yup.string().required()).nullable(),
  orderBy: Yup.mixed<DataName>().required(),
  order: Yup.mixed<Order>().required(),
  page: Yup.number().required(),
  limit: Yup.number().required(),
}).required()

export type ActivitySchema = Yup.InferType<typeof activitiesSchema>

type ActivityForm = FormikContextType<ActivitySchema>

interface Modal {
  activities: Activity[];
  days: string[];
}

/* Internal Variables ------------------------------------------------------- */
const firstDay = new Date(new Date().getFullYear(), 0, 2).toISOString().split('T')[0]
const lastDay = new Date(new Date().getFullYear(), 11, 32).toISOString().split('T')[0]

export const initialValues: ActivitySchema = {
  userIds: [],
  projectIds: [],
  clientIds: [],
  order: 'desc',
  orderBy: 'date',
  page: 1,
  limit: 10,
  startDate: firstDay,
  endDate: lastDay,
}

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
  white-space: pre-wrap;
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

const DeleteButton = styled(Button)`
  height: 35px;
  min-height: 35px;
  width: 40px;
  min-width: 40px;
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesPageProps {}

const ActivitiesPage: React.FC<ActivitiesPageProps> = () => {
  const authInfo = useAuthInfo()
  const activitiesForm = useAppSelector(getActivitiesForm)
  const dispatch = useAppDispatch()
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ modal, setModal ] = useState<Modal>({ activities: [], days: []})
  const [ deleteModal, setDeleteModal ] = useState<Activity | null>()
  const [ loading, setIsLoading ] = useState<boolean>(false)

  const {
    currentData: userList = [],
    isFetching: isFetchingUserList,
  } = useGetUserListQuery()
  const {
    currentData: projectList = [],
    isFetching: isFetchingProjectList,
  } = useGetActiveProjectListQuery()
  const {
    currentData: clientList = [],
    isFetching: isFetchingClientList,
  } = useGetClientListQuery()
  const [
    submitDeleteActivity,
  ] = useDeleteActivityMutation()
  const {
    currentData: settings,
    isFetching: isFetchingSettings,
  } = useGetSettingsQuery()

  const formikForm: ActivityForm = useForm<ActivitySchema>(
    {
      initialValues: activitiesForm ?? initialValues,
      validationSchema: activitiesSchema,
    },
  )

  useEffect(() => {
    dispatch(setActivitiesForm(formikForm.values))
  }, [ formikForm.values ])

  const {
    currentData: activityPagination,
    isFetching: isFetchingActivityPagination,
  } = useGetActivityListQuery(formikForm.values as unknown as ActivityListParams)

  useEffect(() => {
    if (activityPagination?.meta?.total !== undefined && activityPagination?.meta?.total !== totalRows) {
      setTotalRows(activityPagination?.meta?.total)
    }
  }, [ activityPagination, isFetchingActivityPagination ])

  const setOrder = (order: Order) => formikForm.setFieldValue('order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('orderBy', orderBy)
  const setLimit = (limit: number) => formikForm.setFieldValue('limit', limit)
  const setPage = (startIndex: number) => formikForm.setFieldValue('page', startIndex)

  const openDeleteActivitiesModal = (activity: Activity) => {
    setDeleteModal(activity)
  }

  const cols: ColumnHeader[] = [
    {
      id: 'date',
      label: 'Date',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'nbHours',
      label: "Nombre d'heures",
    },
    {
      id: 'userInfo.firstNameWithInitial',
      label: 'Acteur',
      sortId: 'userId',
    },
    {
      id: 'client.name',
      label: 'Client',
      sortId: 'clientId',
    },
    {
      id: 'project',
      label: 'Projet',
      sortId: 'projectId',
      render: (project: Project) => `${project.name} (${project.id})`,
      renderForExport: (project: Project) => `${project.name} (${project.id})`,
    },
    {
      id: 'imputation.name',
      label: 'Imputation',
      sortId: 'imputationId',
    },
    {
      id: 'comment',
      label: 'Commentaire',
    },
    {
      id: 'id',
      label: 'Actions',
      removeFromExport: true,
      render: (id: number, row) => (
        <DeleteButton
          variant="contained"
          color="error"
          onClick={(e) => {e.stopPropagation(); openDeleteActivitiesModal(row as Activity)}}
        >
          <DeleteOutlined />
        </DeleteButton>
      ),
    },
  ]

  const exportDataToXLSX = () => {
    activityPagination?.data && exportToXLSX(cols.filter((col) => !col.removeFromExport), activityPagination.data, "Comptes rendus d'activité")
  }

  const onDeleteActivity = (id: number | false) => {
    if (id) {
      setIsLoading(true)
      submitDeleteActivity(id)
        .then((response) => {
          if (isApiError(response)) {
            toast.error(`Une erreur est survenue.`)
          }
        })
        .catch(console.error)
        .finally(() => setIsLoading(false))
    }
    setDeleteModal(null)
  }

  const onEditActivity = (activity: Activity) => {
    setModal({ activities: [ activity ], days: [ DateUtils.APIStrToShortDate(activity.date) ]})
  }

  const setWeekModal = () => {
    function getDatesOfWeek(): string[] {
      const currentDate = new Date()
      const currentDay = currentDate.getDay()

      const startDate = new Date(currentDate)
      startDate.setDate(currentDate.getDate() - currentDay)

      const datesArray: string[] = []

      for (let i = 0; i < 7; i++) {
        const date = new Date(startDate)
        date.setDate(startDate.getDate() + i)

        if (DateUtils.getCalendarHourAmount(date.getDay(), settings) !== 0) {
          datesArray.push(date.toISOString())
        }
      }

      return datesArray
    }

    setModal({ activities: [], days: getDatesOfWeek() })
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Comptes Rendus d'Activité
        <ButtonsContainer>
          <LongButton
            variant="outlined"
            onClick={exportDataToXLSX}
          >
            Exporter
          </LongButton>
          <LongButton
            variant="contained"
            onClick={() => setModal({ activities: [], days: [ new Date().toISOString() ]})}
            disabled={isFetchingSettings}
          >
            Nouvelle activité
          </LongButton>
          <LongButton
            variant="contained"
            color="secondary"
            onClick={setWeekModal}
            disabled={isFetchingSettings}
          >
            Nouvelle semaine
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <ActivitiesCalendar
        startDate={formikForm.values.startDate || firstDay}
        endDate={formikForm.values.endDate || lastDay}
        isAdmin={authInfo.isAdmin ?? false}
        userIds={formikForm.values.userIds ?? []}
        setModal={setModal}
      />
      <FilterDesktopContainer>
        <ActivitiesFilters
          userList={userList}
          isFetchingUserList={isFetchingUserList}
          isAdmin={authInfo.isAdmin ?? false}
          clientList={clientList}
          isFetchingClientList={isFetchingClientList}
          projectList={projectList}
          isFetchingProjectList={isFetchingProjectList}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <ActivitiesFilters
            userList={userList}
            isFetchingUserList={isFetchingUserList}
            isAdmin={authInfo.isAdmin ?? false}
            clientList={clientList}
            isFetchingClientList={isFetchingClientList}
            projectList={projectList}
            isFetchingProjectList={isFetchingProjectList}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      <LoadingOverlay isLoading={isFetchingActivityPagination || loading}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={activityPagination?.data ?? []}
              setRows={() => null}
              colHeaders={cols}
              defaultOrder={formikForm.initialValues}
              onRowClick={(row) => onEditActivity(row as Activity)}
              limit={formikForm.initialValues.limit}
              sorting={{ setOrder, setOrderBy }}
              pagination={{ totalRows, setLimit, setPage }}
              resultsPerPage={[ 10, 25, 50, 100, totalRows ?? 200 ]}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          <Pagination
            count={activityPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
          {
            activityPagination?.data?.map((activity, index) => (
              <ActivitiesTableMobileCard
                key={`${activity.id}-${index}`}
                activity={activity}
                onEdit={() => onEditActivity(activity)}
                onDelete={() => openDeleteActivitiesModal(activity)}
              />
            ))
          }
          <Pagination
            count={activityPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
        </MobileCardContainer>
      </LoadingOverlay>
      {
        modal.days.length > 0 &&
          <ActivitiesModal
            {...modal}
            open
            clientList={clientList}
            isFetchingClientList={isFetchingClientList}
            projectList={projectList}
            isFetchingProjectList={isFetchingProjectList}
            existingActivities={modal.activities}
            handleClose={() => setModal({ activities: [], days: []})}
            settings={settings}
          />
      }
      {
        deleteModal &&
          <ActivitiesDeleteModal
            open
            activity={deleteModal}
            handleClose={(id: number | false) => onDeleteActivity(id)}
          />
      }
    </Form>
  )
}

export default ActivitiesPage
